@tailwind base;
@tailwind components;
@tailwind utilities;

/* Remove blue background on mobile tap. */
@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
}

@layer components {
  .text-wrap-balance {
    text-wrap: balance;
  }
}

:root {
  --foreground-color: #fff;
}

body {
  color: var(--foreground-color);
  font-family: var(--font-roboto);
}

option {
  font-family: var(--font-roboto), sans-serif;
}

.prose {
  --tw-prose-bold: #fff;
  --tw-prose-headings: #fff;
  --tw-prose-links: #fff;
  color: #fff;
}

.item-prose p > strong:first-child {
  font-size: 1.5rem;
  line-height: 2rem;
  color: rgb(45 132 119);
  font-weight: normal;
  margin-top: 0.25em;
  margin-bottom: -0.5em;
  display: block;
}

.single-page-content p:first-child {
  color: #ffc400;
  font-size: 1.5em;
  line-height: 1.4;
}

.single-page-content h2 {
  color: #ffc400;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: auto;
  object-fit: cover;
}

.fullscreen-modal
  .image-gallery-content
  .image-gallery-slide
  .image-gallery-image {
  object-fit: contain;
}

.image-gallery-thumbnail-inner {
  height: 100%;
}

.image-gallery-thumbnail-image {
  height: 100%;
  object-fit: cover;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.carousel::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.carousel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide Cancel button in HTML 5 search input */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

@media (max-height: 680px) {
  .nav-open {
    overflow: hidden;
  }
}

body.overflow-hidden .image-gallery,
body.overflow-hidden .navbar {
  z-index: 0;
}

body.overflow-hidden .sticky-cont {
  z-index: 5;
}
