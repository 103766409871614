.subcategories {
  display: grid;
  grid-template-rows: 0fr;
  transition: none;
}

.subcategories[aria-hidden='false'] {
  grid-template-rows: 1fr;
  transition: grid-template-rows 500ms;
}

.subcategories > div {
  overflow: hidden;
}
